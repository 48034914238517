<template>
    <div class="container">
      <div class="part_1">
        <div class="part_1_1" style="display: flex">
          <div class="part_1_1_1" style="display: flex;align-items: center;">
            <img :src="userInfo.vipIcon" alt="">
            <div class="part_1_1_1_1">
              <span>Restantes <span style="font-weight: 700;margin: 0 3px;color: #EA4E3D">{{userInfo.nextVipName}}</span></span>
              <span>aposte mais <strong>{{userInfo.nextExpLimit | formatNumber}}</strong></span>
            </div>
          </div>
          <div class="part_1_2">
            <div class="part_1_2_1">Coletar Tudo</div>
            <div class="part_1_2_2"  @click="toUrl('/home/records?num=15')">Histórico</div>
          </div>
        </div>
      </div>
      <h2 style="margin: 10px;color: #fff">Lista de níveis VIP</h2>

      <div class="p1" ref="scrollContainer">
        <div @click="changeTab(1)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 1 }" class="colorCSS">Bônus De Aumento De Nível</div>
<!--        <div @click="changeTab(2)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 2 }" class="colorCSS">Bônus Semanal</div>-->
<!--        <div @click="changeTab(3)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 3 }" class="colorCSS">Bônus Mensal</div>-->
<!--        <div @click="changeTab(4)" :class="{ 'colorCSS': true, 'activeDiv': acNum == 4 }" class="colorCSS">Privilégio VIP</div>-->
      </div>

      <div v-if="acNum ==1">
        <div class="d1">
          <div class="d1_1" style="text-align: center">
            <span>Nível</span>
            <span style="margin-left: 20px">Aposta para promoção</span>
            <span>Acumulado de recarga</span>
            <span style="width: 120px;text-align: center">Bônus De Aumento De Nível</span>
          </div>
          <div :id="index"
               :class="{ 'd1_2': true, 'd1_2_double': index % 2 == 0 }"
               :style="{ 'border': index ==  NowVipLevel? '1px solid #ffaa09' : 'none' }"
               v-for="(item, index) in vipList"
               :key="index">
            <img style="width: 39px" :src="item.iconUrl" alt="">
            <div style="text-align: center"> {{item.expLimit | formatNumber}} <van-progress color="#32CD32" v-if="index ==  NowVipLevel ":pivot-text="levelPro" :percentage="percentage" /></div>
            <div style="text-align: center"> {{item.rechargeAmout | formatNumber}} <van-progress color="#32CD32" v-if="index ==  NowVipLevel ":pivot-text="recharge" :percentage="percentageR" /></div>
            <div style="padding-right: 50px;color: #FFAA09">{{item.growupReward | formatNumber}}</div>
          </div>

        </div>
      </div>
      <div v-if="acNum ==2">
        2
      </div>
      <div v-if="acNum ==3">
        3
      </div>
      <div v-if="acNum ==4">
        4
      </div>
      <h2 style="margin: 10px 20px;color: #fff">Instruções sobre regras VIP</h2>

      <div style="margin: 20px; text-align: justify">
        1.Padrão de promoção: atenda aos requisitos da promoção VIP (ou seja, a recarga ou apostas eficazes podem atender às condições), você pode avançar para o nível VIP correspondente e obter o bônus de promoção correspondente.O bônus pode ser recebido de tempos em tempos;
        <br>
        2.Salário diário: Se a recarga diária e as apostas válidas atenderem aos requisitos salariais diários do nível atual, você poderá obter o bônus salarial diário correspondente. Se você avançar para vários níveis consecutivos, poderá obter o bônus salarial diário de todos os níveis.O bônus pode ser recebido de tempos em tempos;<br>
        3.Salário Semanal: Se a recarga semanal e as apostas válidas atenderem ao nível atual de requisitos salariais semanais, você poderá obter o bônus salarial semanal correspondente. Se você avançar para vários níveis consecutivos, poderá obter todos os níveis de bônus salarial semanal.Disponível todas as segundas-feiras 00:00:00-23:59:59<br>
        4.Lulu mensal: recarga mensal e apostas efetivas para atender ao nível atual do Lulu mensal. Você pode obter o bônus de prêmio mensal correspondente.Disponível no dia 1 de todo mês de 00:00:00 a 23:59:59<br>
        5.Tempo de Expiração da Recompensa: O bônus recebido fica disponível por 1 dias. Se não for resgatado durante esse período, ele será automaticamente creditado na conta. Por exemplo: se uma recompensa for obtida em 1º de janeiro e retida por 1 dias, será automaticamente creditada na conta em 3 de janeiro às 00:00:00;<br>
        6.Instruções para auditoria: o bônus VIP oferecido pode ser levantado apenas após o cumprimento do requisito de rollover 1x (ou seja, auditoria, apostas ou apostas válidas), independentemente da plataforma em que joga;<br>
        7.Declarações: Esta função está limitada às operações normais dos titulares de contas. É proibido alugar contas, efetuar apostas sem risco (apostas com contas diferentes, swiping mútuo e swiping de odds baixas), arbitragem viciosa, utilizar plug-ins, robôs, exploração de acordos, lacunas, interfaces, controlo de grupo ou outros meios técnicos de participação; caso contrário, uma vez provado, a plataforma tem o direito de proibir os membros de iniciar sessão, suspender a utilização do nosso website, e confiscar o bônus e os ganhos indevidos, sem qualquer aviso especial;<br>
        8.Instruções: Ao reclamar o bônus VIP, considera-se que os membros aceitam e cumprem as regras correspondentes. A fim de evitar diferenças na compreensão do texto, a plataforma reserva o direito final de interpretar esta atividade.
      </div>

      <tabbar></tabbar>

    </div>
</template>

<script>
import {getUserLevelList,getUserInfoApi} from "@/api";
import tabbar from "@/components/Tabbar.vue";

export default {
  components: {tabbar},
  data() {
      return {
          acNum: '1',
          vipList: '',
          userInfo: '',
          NowVipLevel: '',
          levelPro: '',
          percentage: '',
          recharge: '',
          percentageR: '',
      }
  },
  mounted() {
      if (this.$store.state.userInfo){
          this.userInfo = this.$store.state.userInfo
          this.NowVipLevel = this.userInfo.lvId + 1;
          console.log(this.userInfo)
          // var aaa = this.userInfo.nextVipName;
          // this.NowVipLevel = aaa.replace(/VIP/, '');
          // console.log("newStr:"+this.NowVipLevel);
      }
    // 在组件挂载后，通过ref获取容器，并设置样式
    this.$refs.scrollContainer.style.overflowX = 'auto';
    this.$refs.scrollContainer.style.whiteSpace = 'nowrap';
  },
  created() {
      this.getUserLevelList()
      this.getUserInfoApi()
  },
    methods:{
      toUrl(url){this.$router.push(url)},
    changeTab(num){
      this.acNum = num
    },
    getUserLevelList(){
      getUserLevelList().then(res=>{
        this.vipList = res.data
      })
    },
        getUserInfoApi(){
            getUserInfoApi().then(res=>{
                if(res.code==200){
                    this.balance = res.data.balance
                    this.isSetPayPassword = res.data.isSetPayPassword
                    this.userInfo = res.data;
                    this.levelPro = parseInt(res.data.bonusProcceed) + "/" + res.data.nextExpLimit;
                    this.recharge = parseInt(res.data.rechargeSum) + "/" + res.data.nextRechargeLimit;
                    this.percentage = parseInt(res.data.bonusProcceed / res.data.nextExpLimit)
                    this.percentageR=  parseInt(res.data.rechargeSum / res.data.nextRechargeLimit)
                    // console.log("this.isSetPayPassword:"+this.isSetPayPassword)
                }
            })
        },


  },
}
</script>

<style lang="scss" scoped>
.part_1{
  padding: 10px;
  .part_1_1{
    width: 100%;
    background: #1c1c1c;
    height: 89px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    .part_1_1_1{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 55px !important;
        height: 55px !important;
        border-radius: 50%;
      }
      .part_1_1_1_1{
        display: flex;
        flex-direction: column;
      }
    }
  }
  .part_1_2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .part_1_2_1{
      width: 66px;
      //height: 24px;
      font-size: 12px;
      border-radius: 5px;
      background: #6e6e6e;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      margin-bottom: 5px;
    }
    .part_1_2_2{
      width: 66px;
      height: 35px;
      //height: 24px;
      font-size: 12px;
      border-radius: 5px;
      background: #feb705;
      display: flex;
      color: #000;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

}


.p1{
  display: flex;
  background: #1c1c1c;
  border-bottom: 1px solid #ccc;
  div{
    padding: 10px;
    height: 100%;
  }
  .colorCSS{
    color: #fff;
  }
  .activeDiv{
    color: #FF9800;
    border-bottom: 1px solid;
  }
}

.d1{
  //border:1px solid #ccc;
  margin: 10px;
  border-radius: 5px;
  .d1_1{
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
      /*font-size: 1px;*/
    align-items: center;
  }
  .d1_2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
    //border: 1px solid;
    border-radius: 5px;
  }
  .d1_2_double{
    background: #1c1c1c;
  }

  .d1_2_border{
    border: 1px solid #FFAA09;
  }
 ::v-deep(.van-progress){
   width: 100px;
   margin-top: 5px ;
 }
}
</style>
